import { useState } from 'react'
import c from './ApartmentSlider.module.scss'
import ImgPopup from '../../../imgPopup/ImgPopup'

interface Props {
    images: string[]
    setActiveImg: (val: string) => void
}

const ApartmentSlider: React.FC<Props> = ({ images, setActiveImg }) => {
    const [position, setPosition] = useState<number>(0)

    const changeSlide = (side: boolean) => {
        if (side && images.length * 100 - 100 > position) {
            setPosition(position + 100)
        } else if (!side && position > 0) {
            setPosition(position - 100)
        }
    }

    return (
        <div className={c.container}>
            <div
                className={c.line}
                style={{
                    transform: `translateX(${-position}vw)`,
                }}
            >
                {images.map((slide) => (
                    <div
                        onClick={() => setActiveImg(slide)}
                        key={slide}
                        className={c.slide}
                    >
                        <img
                            className={c.slide__img}
                            src={slide}
                            alt="realsy management offers"
                        />
                    </div>
                ))}
            </div>
            <button
                style={{
                    opacity: position === 0 ? '0.3' : '1',
                }}
                onClick={() => changeSlide(false)}
                className={`${c.button} ${c.left}`}
            ></button>
            <button
                style={{
                    opacity:
                        position === images.length * 100 - 100 ? '0.3' : '1',
                }}
                onClick={() => changeSlide(true)}
                className={`${c.button} ${c.right}`}
            ></button>
        </div>
    )
}

export default ApartmentSlider
