import c from './Employees.module.scss'

const owners = [
    {
        name: 'Igor Sobotnyk',
        number: '+48573408585',
        position: 'Co-founder',
        img: './img/aaa_igor.png',
    },
    {
        name: 'Dmytro Selin',
        number: '+48573408404',
        position: 'Co-founder',
        img: './img/aaa_dima.png',
    },
]

const employees = [
    {
        name: 'Ewelina Frydrych',
        number: '+48453272622',
        position: 'Manager Działu Zarządzania',
        email: 'manager@realsymanagement.pl',
    },
    {
        name: 'Olga Vinhlevska',
        number: '+48453456881',
        position: 'Doradca ds. Nieruchomości',
        email: 'o.vinhlevska@realsymanagement.pl',
    },
    {
        name: 'Oleksandr Logovskyi',
        number: '+48451188400',
        position: 'Doradca ds. Nieruchomości',
        email: 'o.logovskyi@realsymanagement.pl',
    },
    {
        name: 'Nikita Makarenko',
        number: '+48451639891',
        position: 'Doradca ds. Nieruchomości',
        email: 'm.makarenko@realsymanagement.pl',
    },
    {
        name: 'Paweł Malinowski',
        number: '+48451084004',
        position: 'Doradca ds. Nieruchomości',
        email: 'p.malinowski@realsymanagement.pl',
    },
]

const Employees = () => {
    return (
        <div className={c.container}>
            <div className={c.employees}>
                {owners.map((e) => (
                    <div className={c.employees__item}>
                        <img
                            src={e.img}
                            alt="employee"
                            className={c.employees__img}
                        />
                        <p className={c.employees__name}>{e.name}</p>
                        <p className={c.employees__position}>{e.position}</p>
                        <a
                            className={c.employees__number}
                            href={'tel:' + e.number}
                        >
                            {e.number}
                        </a>
                    </div>
                ))}
            </div>
            <div className={c.employees}>
                {employees.map((e) => (
                    <div className={c.employees__item_2}>
                        <p className={c.employees__name}>{e.name}</p>
                        <p className={c.employees__position}>{e.position}</p>
                        <a
                            className={c.employees__number}
                            href={'tel:' + e.number}
                        >
                            {e.number}
                        </a>
                        <p className={c.employees__position}>{e.email}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Employees
