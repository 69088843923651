import c from './ContactPopup.module.scss'
import { useEffect, useState } from 'react'
import Contact from '../contact/Contact'

interface Props {
    setContactPopup: (val: boolean) => void
    contactPopup: boolean
}

const ContactPopup: React.FC<Props> = ({ setContactPopup, contactPopup }) => {
    useEffect(() => {
        setTimeout(() => {
            setContactPopup(true)
        }, 3000)
    }, [])
    return (
        <article
            style={{ display: contactPopup ? 'block' : 'none' }}
            className={c.popup}
        >
            <div className={c.popup__header}>
                <button
                    onClick={() => setContactPopup(false)}
                    className={c.popup__close}
                ></button>
            </div>
            <div className={c.contact__container}>
                <Contact />
            </div>
        </article>
    )
}

export default ContactPopup
