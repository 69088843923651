import { useEffect, useRef, useState } from 'react'
import c from './Reviews.module.scss'
import { useMyContext } from '../../context/Context'

const data1 = [
    { id: 1, src: './img/pl1.png' },
    { id: 2, src: './img/pl2.png' },
    { id: 3, src: './img/pl3.png' },
    { id: 4, src: './img/pl4.png' },
    { id: 5, src: './img/pl5.png' },
]

const data2 = [
    { id: 1, src: './img/en1.png' },
    { id: 2, src: './img/en2.png' },
    { id: 3, src: './img/en3.png' },
    { id: 4, src: './img/en4.png' },
    { id: 5, src: './img/en5.png' },
]

const Reviews = () => {
    const { data } = useMyContext()
    const slides = data === 'pl' ? data1 : data2
    const [active, setActive] = useState(2)
    const divRef = useRef<HTMLDivElement | null>(null)
    const [width, setWidth] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            if (divRef.current) {
                setWidth(divRef.current.getBoundingClientRect().width)
            }
        }
        handleResize()
        if (width < 500) {
            setActive(1)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleSlide = (b: boolean) => {
        const length = slides.length
        if (b === true && active != length) {
            setActive(active + 1)
        } else if (b === false && active != 1) {
            setActive(active - 1)
        }
    }

    const getSlideWidth = () => {
        if (width >= 1200) {
            return 400
        } else if (width < 1200 && width >= 800) {
            return 260
        } else {
            return 300
        }
    }

    return (
        <div ref={divRef} className={c.rev__container}>
            <div className={c.rev}>
                <div className={c.rev__line_container}>
                    <div
                        className={c.rev__line}
                        style={{
                            transform:
                                width < 800
                                    ? `translate(${
                                          -(active - 1) * getSlideWidth()
                                      }px)`
                                    : `translate(${
                                          -(active - 2) * getSlideWidth()
                                      }px)`,
                        }}
                    >
                        {slides.map((e) => (
                            <div
                                style={{
                                    scale: e.id !== active ? '0.7' : '1',
                                    background: `url(${e.src}) 0 0/ cover no-repeat`,
                                }}
                                key={e.id}
                                className={c.rev__item}
                            >
                                <img src={e.src} alt="img" />
                            </div>
                        ))}
                    </div>
                    <button
                        style={{ display: active === 1 ? 'none' : 'block' }}
                        onClick={() => handleSlide(false)}
                        className={c.rev__left}
                    >
                        <span></span>
                    </button>
                    <button
                        style={{
                            display:
                                active === slides.length ? 'none' : 'block',
                        }}
                        onClick={() => handleSlide(true)}
                        className={c.rev__right}
                    >
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Reviews
