import c from './ImgPopup.module.scss'

interface Props {
    img: string
    display: boolean
    setDisplay: () => void
}

const ImgPopup: React.FC<Props> = ({ img, display, setDisplay }) => {
    return (
        <div
            className={c.container}
            style={{
                display: display ? 'block' : 'none',
            }}
        >
            <div className={c.container__header}>
                <button
                    className={c.container__close}
                    onClick={setDisplay}
                ></button>
            </div>
            <div className={c.container__img}>
                <img src={img} alt="Example Image" />
            </div>
        </div>
    )
}

export default ImgPopup
